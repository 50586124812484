<template>
  <b-form>
    <b-row>
      <b-table
        striped
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items.itens"
        :fields="filteredFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      ></b-table>
      <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-row>
  </b-form>
</template>
<script>
  import Ripple from 'vue-ripple-directive'
  import { formatarData } from '@/libs/utils'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        formatarData,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 12,
        currentPage: 1,
        fields: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return value ? formatarData(value) : ''
            },
          },

          {
            key: 'nome',
            label: 'Nome do Órgão',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'nomeCompleto',
            label: 'Nome do Tomador',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'name',
            label: 'Nome do Usuário',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },

          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'cnpj', label: 'CNPJ', sortable: true },

          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? formatarData(value) : ''
            },
          },

          {
            key: 'sexo',
            label: 'sexo',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          { key: 'matriculas', label: 'Matrícula', sortable: true },
          {
            key: 'categoria',
            label: 'categoria',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },

          {
            key: 'email',
            label: 'E-mail',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'genero',
            label: 'Gênero',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'role',
            label: 'Tipo de usuario',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'responsavelNome',
            label: 'Nome do Responsável',
            sortable: true,
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
        ],
        totalRows: 0,
        items: [],
        filteredFields: [],
      }
    },
    mounted() {
      this.gerarRelatorio()
    },
    beforeUpdate() {
      this.gerarRelatorio()
    },
    methods: {
      gerarRelatorio() {
        this.items.itens = this.parametro.item
        this.totalRows = this.parametro.item.length
        this.items.type = this.parametro.type
        this.filteredColuns()
      },
      filteredColuns() {
        this.filteredFields = []

        for (const field of this.fields) {
          const key = field.key
          const hasValue = this.items.itens.some((item) => item.hasOwnProperty(key))
          if (hasValue) {
            this.filteredFields.push(field)
          }
        }
      },
    },
    watch: {
      ['parametro'](value, oldValue) {
        this.gerarRelatorio()
      },
    },
  }
</script>
