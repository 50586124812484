<template>
  <b-card-code no-body>
    <b-card-body>
      <validation-observer ref="formPesquisar">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group label="Órgão" label-for="orgao">
                <v-select-pt
                  name="orgao"
                  v-model="pesquisar.orgao"
                  :options="orgaoOpcao"
                  :reduce="(option) => option.id"
                  label="nome"
                >
                </v-select-pt>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Grupo Folha" label-for="grupoFolha">
                <v-select-pt
                  name="grupoFolha"
                  v-model="pesquisar.grupoFolha"
                  :options="grupoFolhaOpcao"
                  :reduce="(option) => option.id"
                  label="nomeDoGrupo"
                >
                </v-select-pt>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 25px; width: 160px"
                @click="goPesquisar()"
              >
                Pesquisar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>

    <b-table
      v-show="itemsTable.length > 0"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="itemsTable"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-1" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item @click="visulizarRelatorio(row.item)">
            <feather-icon icon="EyeIcon" size="16" />
            <span class="align-middle ml-50">Visualizar</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="exportToPDF(row.item)">
            <feather-icon icon="FileTextIcon" size="16" />
            <span class="align-middle ml-50">Exportar PDF</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="exportToExcel(row.item)">
            <feather-icon icon="CheckCircleIcon" size="16" />
            <span class="align-middle ml-50" title="Exportar tabela para excel">Exportar Excel</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Agenda Bank - Relatório"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1125px"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="tableRelatCadastro"
      >
        <section slot="pdf-content">
          <div class="fundoRelatorio titulo">
            <div class="d-none d-lg-flex align-items-center fundoRelatorio">
              <img :src="logoAgenda" alt="Logo" />

              <h2 class="brand-text text-primary ml-1" style="margin-bottom: 0px; margin-left: 0px !important">Agenda Bank</h2>
            </div>
            <div class="lineBlue"></div>
            <div class="lineGray"></div>
            <h1 class="fundoRelatorio instituto" style="margin-bottom: 0px; margin-top: 5px">
              {{ userData.Instituto.nome }}
              <!-- {{ "AGENDA ASSESSORIA" }} -->
            </h1>
          </div>

          <!-- Nome do Relatório -->
          <div class="fundoRelatorio tituloRelatorio">Relatório de Cadastro</div>

          <b-card-code>
            <b-table id="tableRelatCadastro" :head-variant="'light'" :items="relatorio.item" :fields="fieldsPDF"></b-table>
          </b-card-code>
        </section>
      </vue-html2pdf>
    </div>

    <b-modal
      size="xl"
      id="modal-relatorio"
      ref="modal-relatorio"
      centered
      :title="relatorio.type"
      ok-title="Fechar"
      ok-only
      ok-variant="outline-secondary"
      no-close-on-backdrop
    >
      <relatorioCadastrosTable :parametro="relatorio" />
    </b-modal>
  </b-card-code>
</template>

<script>
  import {
    BCard,
    BPagination,
    BTab,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BTable,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BButton,
    BForm,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarDataGlobal } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import VueHtml2pdf from 'vue-html2pdf'
  import relatorioCadastrosTable from './relatorioCadastrosTable.vue'
  import vSelect from 'vue-select'

  export default {
    components: {
      BCard,
      BPagination,
      BTab,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BTable,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BButton,
      BForm,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      BDropdown,
      BDropdownItem,
      BCardCode,
      formatarData,
      relatorioCadastrosTable,
      vSelect,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        aImg: require('@/assets/images/logo/Alogo2.png'),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        required,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        formatarData,
        isBusy: true,
        acesso: {},
        orgaoOpcao: [],
        grupoFolhaOpcao: [],
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fieldsPDF: [],
        fieldsPDFTotal: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return value ? formatarData(value) : ''
            },
          },

          {
            key: 'nome',
            label: 'Nome do Órgão',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'nomeCompleto',
            label: 'Nome do Tomador',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'name',
            label: 'Nome do Usuário',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },

          { key: 'cpf', label: 'CPF' },
          { key: 'cnpj', label: 'CNPJ' },

          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
            formatter: (value, key, item) => {
              return value ? formatarData(value) : ''
            },
          },

          {
            key: 'sexo',
            label: 'sexo',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          { key: 'matriculas', label: 'Matrícula' },
          {
            key: 'categoria',
            label: 'categoria',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },

          {
            key: 'email',
            label: 'E-mail',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'genero',
            label: 'Gênero',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'role',
            label: 'Tipo de usuario',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
          {
            key: 'responsavelNome',
            label: 'Nome do Responsável',
            formatter: (value, key, item) => {
              return value ? value.toUpperCase() : ''
            },
          },
        ],
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'type', label: 'Tipo de Relatório' },
        ],
        items: [],
        itemsTable: [],
        relatorio: [],
        role: [
          {
            master: 'success',
            admin: 'primary',
            user: 'warning',
          },
        ],
      }
    },
    mounted() {
      this.carregarOrgaos()
      this.carregarGrupoFolha()
    },
    computed: {
      logoAgenda() {
        return this.userData.Instituto?.logoRelatorio?.logo ?? this.aImg
      },
    },
    methods: {
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.orgaoOpcao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregarGrupoFolha() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .post(`cadastro/grupoFolha/GetPesquisarPorOrgao`, {
              institutoId: this.userData.institutoSelecionado,
              orgaoId: this.pesquisar.grupoFolha,
            })
            .then((response) => {
              this.grupoFolhaOpcao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      isDateValid(data) {
        var Title
        const day = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD')
        const validDate =
          data == ''
            ? ''
            : /^(((0[1-9]|[12][0-9]|3[01])([-.\/])(0[13578]|10|12)([-.\/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.\/])(0[469]|11)([-.\/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.\/])(02)([-.\/])(\d{4}))|((29)(\.|-|\/)(02)([-.\/])([02468][048]00))|((29)([-.\/])(02)([-.\/])([13579][26]00))|((29)([-.\/])(02)([-.\/])([0-9][0-9][0][48]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][2468][048]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][13579][26])))$/.test(
                data,
              )
            ? true
            : false
        if (validDate) {
          const insertData = moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD')
          if (insertData > day) {
            Title = 'Erro: A data inserida é maior que a data atual. Pesquisa inválida. '
            this.msgError(Title)
          } else {
            return true
          }
        } else {
          Title = 'Erro: A data inserida não é válida. '
          this.msgError(Title)
        }
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      goPesquisar() {
        this.$refs.formPesquisar.validate().then((success) => {
          if (success) {
            this.isBusy = true
            useJwt
              .pesquisar('relatorio/GetAllCadastro', {
                ...this.pesquisar,
                institutoSelecionado: this.userData.institutoSelecionado,
              })
              .then((response) => {
                this.isBusy = !this.isBusy
                this.items.cadastroOrgao = response.data.cadastroOrgao
                this.items.cadastroInstituto = response.data.cadastroInstituto
                this.items.cadastroUser = response.data.cadastroUser
                this.items.cadastroTomador = response.data.cadastroTomador
                this.itemsToDisplay()
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
      itemsToDisplay() {
        const filteredItems = []

        if (this.items.cadastroOrgao.length > 0) {
          filteredItems.push({ item: this.items.cadastroOrgao, type: 'Órgãos cadastrados' })
        }
        if (this.items.cadastroInstituto.length > 0) {
          filteredItems.push({ item: this.items.cadastroInstituto, type: 'Institutos cadastrados' })
        }
        if (this.items.cadastroUser.length > 0) {
          filteredItems.push({ item: this.items.cadastroUser, type: 'Usuários cadastrados' })
        }
        if (this.items.cadastroTomador.length > 0) {
          filteredItems.push({ item: this.items.cadastroTomador, type: 'Tomadores cadastrados' })
        }
        this.itemsTable = filteredItems
      },
      visulizarRelatorio(item) {
        this.relatorio = item
        this.$refs['modal-relatorio'].show()
      },
      filteredColuns() {
        this.fieldsPDF = []

        for (const field of this.fieldsPDFTotal) {
          const key = field.key
          const hasValue = this.relatorio.item.some((item) => item.hasOwnProperty(key))
          if (hasValue) {
            this.fieldsPDF.push(field)
          }
        }
      },
      exportToPDF(item) {
        this.relatorio = item
        this.filteredColuns()
        this.$refs.tableRelatCadastro.generatePdf()
      },
      exportToExcel(item) {
        this.relatorio = item
        this.filteredColuns()

        var tab_text = "<table border='2px'>"
        var j = 0
        var tab = document.getElementById('tableRelatCadastro')

        for (j = 0; j < tab.rows.length; j++) {
          tab_text = tab_text + tab.rows[j].innerHTML + '</tr>'
          tab_text = tab_text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }

        tab_text = tab_text + '</table>'
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, '') //remove if u want links in your table
        tab_text = tab_text.replace(/<img[^>]*>/gi, '') // remove if u want images in your table
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, '') // reomves input params

        var ua = window.navigator.userAgent
        var msie = ua.indexOf('MSIE ')
        var sa
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          // If Internet Explorer
          txtArea1.document.open('txt/html', 'replace')
          txtArea1.document.write(tab_text)
          txtArea1.document.close()
          txtArea1.focus()
          sa = txtArea1.document.execCommand('SaveAs', true, 'Relatorio.xlsx')
        } //other browser not tested on IE 11
        else sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text))

        return sa
      },
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
